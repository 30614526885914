<script>
  import { CDateRangePicker } from '@coreui/vue-pro';
  
  export default {
    components: {
        CDateRangePicker,
    },
    data() {
        const today = new Date();
        const dayOfWeek = today.getDay();
        const lastSunday = new Date();
        lastSunday.setDate( today.getDate() - dayOfWeek );

        return {
            vStartDate: lastSunday,
            vEndDate: today,
            options: {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                weekday: 'long',
            },
            isUpdating: false,
        };
    },
    mounted() {
        console.log('Date Range Picker Mounted');
    },
    methods: {
        async checkDates() {
            if (this.isUpdating || this.vStartDate == null || this.vEndDate == null ) return; // Prevent multiple calls
            this.isUpdating = true; // Lock
            await new Promise( ( resolve ) => {
                if ( this.vStartDate != '' && this.vEndDate != '' && this.vStartDate < this.vEndDate ) {
                    $( '#performance_loader' ).fadeIn( 500 );
                    setTimeout( () => {
                        this.getData();
                        resolve();
                    }, 2500 );
                }

            } );

            console.log('Update completed');
            this.isUpdating = false; // Unlock
        },
        getData() {
            let startDate = this.formatDate( this.vStartDate );
            let endDate = this.formatDate( this.vEndDate );
            let tBody = document.querySelector( '#performance_tbody' );

            const token = $('input[name="_token"]').val();
            const url = `/dashboard/performance?start_date=${startDate}&end_date=${endDate}&_token=${token}`;
            // fetch to get data for the table.
            fetch( url, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then( res => res.json() )
            .then( data => { 
                console.log( data );
                tBody.innerHTML = '';
                let tbodyBuild = '';
                Object.values(data).forEach( (user) => {
                    tbodyBuild += `<tr>
                        <td>
                            <span class="m-0">${user.id}</span>
                        </td>
                        <td>
                            <h5 class="m-0">${user.firstname} ${user.lastname}</h5>
                        </td>
                        <td>
                            <span class="m-0">${user.orders_processed}</span>
                        </td>
                        <td>
                            <span class="m-0">${user.time_worked}</span>
                        </td>
                        <td>
                            <span class="m-0">${user.productivity_rate}</span>
                        </td>
                        <td>
                            <span class="m-0">${user.orders_pending}</span>
                        </td>
                    </tr>`;
                });
                tBody.innerHTML = tbodyBuild;
                $( '#performance_loader' ).fadeOut( 1000 );

            }, 2000);

        },
        formatDate( dateObj ) {
            const yyyy = dateObj.getFullYear();
            let mm = dateObj.getMonth() + 1; // Months are zero-based, so add 1
            let dd = dateObj.getDate();

            // Add leading zeros if necessary
            if (mm < 10) mm = '0' + mm;
            if (dd < 10) dd = '0' + dd;

            return `${yyyy}-${mm}-${dd}`;
        }
    }
  }
</script>
<template>
  <CDateRangePicker label="Date range" locale="en-US" v-model:start-date="vStartDate" v-model:end-date="vEndDate" @update:start-date="checkDates" @update:end-date="checkDates" />
  <div>
    {{ vStartDate ? vStartDate.toLocaleDateString([], options) : '' }}
    -
    {{ vEndDate ? vEndDate.toLocaleDateString([], options) : '' }}
  </div>
</template>