<template>
    <div class="row mt-6">
        <div class="col-sm-12">
            <div class="card o-hidden mb-4" style="min-height: 35vh;">
                <div class="card-header d-flex align-items-center border-0">
                    <h3 class="w-50 float-left card-title m-0" v-if="selectedTitle">
                        <i class="i-RSS"></i>
                        API Call Data - {{selectedTitle}}
                    </h3>
                    <h3 class="w-50 float-left card-title m-0" v-else>
                        <i class="i-RSS"></i>
                        API Call Data - No Action has been executed.
                    </h3>
                    <div class="dropdown dropleft text-right w-50 float-right">
                        <button class="btn bg-gray-100" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="nav-icon i-Atom"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <a class="dropdown-item" href="#">Retry</a>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="content d-flex" v-if="pulledOrders">
                        <pre id="pulledData" style="display: none;">{{pulledOrders}}</pre>
                    </div>
                    <div id="forLoading" class="content" style="display: none;" v-else>
                        <p class="text-primary text-16 line-height-1 mb-2" style="display: none;">Data To Display Here...</p>
                        <div id="afterLoaded" class="text-16 line-height-1 mb-2 m-auto" style="display: none;">
                            <div class="spinner-border" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            pulledOrders: '',
            selectedTitle: ''
        }
    },
    mounted() {
        $('#afterLoaded').hide();
        $('#forLoading').fadeIn(3000);
        $('#forLoading p.text-primary').fadeIn(3000);
        console.log('AOP Component mounted.');
        var thisComponent = this;
        $('#activatePull').on('click', function(e) {
            e.preventDefault();
            if (thisComponent.pulledOrders != '') {
                $('#pulledData').fadeOut(2000);
                setTimeout(function() {
                    thisComponent.pulledOrders = '';
                }, 2300);
                setTimeout(function() {
                    $('#forLoading').fadeIn(2000);
                    $('#forLoading p.text-primary').fadeIn(2000);
                    $('#afterLoaded').fadeIn(2000);
                }, 2510);
            }
            var target = e.target;
            var dateSelector = $('#dateSelector');
            var dateSelectorVal = dateSelector.val();
            if (target.nodeName == 'I') {
                target = target.parentElement;
            }
            thisComponent.selectedTitle = dateSelector.children("option:selected").text();
            if (dateSelectorVal != '') {
                $('#afterLoaded').fadeIn(1500);
            }
            switch (dateSelectorVal) {
                case "7":
                    fetch('/sc-cyorders')
                        .then(res => res.json())
                        .then(data => {
                            $('#forLoading').fadeOut(3000);
                            setTimeout(function() {
                                thisComponent.pulledOrders = data;
                            }, 3005);
                            setTimeout(function() {
                                $('#pulledData').fadeIn(2000);
                            }, 3010);
                        })
                        .catch(err => {
                            console.log(err.message);
                            $('#forLoading').fadeOut(3000);
                            setTimeout(function() {
                                thisComponent.pulledOrders = 'Unsuccessful';
                            }, 3005);
                            setTimeout(function() {
                                $('#pulledData').fadeIn(2000);
                            }, 3010);
                            setTimeout(function() {
                                $('#pulledData').fadeOut(2000);
                            }, 5000);
                            setTimeout(function() {
                                thisComponent.pulledOrders = '';
                            }, 7000);
                            setTimeout(function() {
                                $('#forLoading').fadeIn(2000);
                                $('#forLoading p.text-primary').fadeIn(2000);
                            }, 7010);
                        });
                    break;
                case "12":
                    fetch('/sc-last24hoursorders')
                        .then(res => res.json())
                        .then(data => {
                            $('#forLoading').fadeOut(3000);
                            setTimeout(function() {
                                thisComponent.pulledOrders = data;
                            }, 3005);
                            setTimeout(function() {
                                $('#pulledData').fadeIn(2000);
                            }, 3010);
                        })
                        .catch(err => {
                            console.log(err.message);
                            $('#forLoading').fadeOut(3000);
                            setTimeout(function() {
                                thisComponent.pulledOrders = 'Unsuccessful';
                            }, 3005);
                            setTimeout(function() {
                                $('#pulledData').fadeIn(2000);
                            }, 3010);
                            setTimeout(function() {
                                $('#pulledData').fadeOut(2000);
                            }, 5000);
                            setTimeout(function() {
                                thisComponent.pulledOrders = '';
                            }, 7000);
                            setTimeout(function() {
                                $('#forLoading').fadeIn(2000);
                                $('#forLoading p.text-primary').fadeIn(2000);
                            }, 7010);
                        });
                    break;
                case "22":
                    fetch('/sc-lmorders')
                        .then(res => res.json())
                        .then(data => {
                            $('#forLoading').fadeOut(3000);
                            setTimeout(function() {
                                thisComponent.pulledOrders = data;
                            }, 3005);
                            setTimeout(function() {
                                $('#pulledData').fadeIn(2000);
                            }, 3010);
                        })
                        .catch(err => {
                            console.log(err.message);
                            $('#forLoading').fadeOut(3000);
                            setTimeout(function() {
                                thisComponent.pulledOrders = 'Unsuccessful';
                            }, 3005);
                            setTimeout(function() {
                                $('#pulledData').fadeIn(2000);
                            }, 3010);
                            setTimeout(function() {
                                $('#pulledData').fadeOut(2000);
                            }, 5000);
                            setTimeout(function() {
                                thisComponent.pulledOrders = '';
                            }, 7000);
                            setTimeout(function() {
                                $('#forLoading').fadeIn(2000);
                                $('#forLoading p.text-primary').fadeIn(2000);
                            }, 7010);
                        });
                    break;
                case "28":
                    fetch('/sc-mtdorders')
                        .then(res => res.json())
                        .then(data => {
                            $('#forLoading').fadeOut(3000);
                            setTimeout(function() {
                                thisComponent.pulledOrders = data;
                            }, 3005);
                            setTimeout(function() {
                                $('#pulledData').fadeIn(2000);
                            }, 3010);
                        })
                        .catch(err => {
                            console.log(err.message);
                            $('#forLoading').fadeOut(3000);
                            setTimeout(function() {
                                thisComponent.pulledOrders = 'Unsuccessful';
                            }, 3005);
                            setTimeout(function() {
                                $('#pulledData').fadeIn(2000);
                            }, 3010);
                            setTimeout(function() {
                                $('#pulledData').fadeOut(2000);
                            }, 5000);
                            setTimeout(function() {
                                thisComponent.pulledOrders = '';
                            }, 7000);
                            setTimeout(function() {
                                $('#forLoading').fadeIn(2000);
                                $('#forLoading p.text-primary').fadeIn(2000);
                            }, 7010);
                        });
                    break;

                case "8":
                    fetch('/sc-lysales')
                        .then(res => res.json())
                        .then(data => {
                            $('#forLoading').fadeOut(3000);
                            setTimeout(function() {
                                thisComponent.pulledOrders = data;
                            }, 3005);
                            setTimeout(function() {
                                $('#pulledData').fadeIn(2000);
                            }, 3010);
                        })
                        .catch(err => {
                            console.log(err.message);
                            $('#forLoading').fadeOut(3000);
                            setTimeout(function() {
                                thisComponent.pulledOrders = 'Unsuccessful';
                            }, 3005);
                            setTimeout(function() {
                                $('#pulledData').fadeIn(2000);
                            }, 3010);
                            setTimeout(function() {
                                $('#pulledData').fadeOut(2000);
                            }, 5000);
                            setTimeout(function() {
                                thisComponent.pulledOrders = '';
                            }, 7000);
                            setTimeout(function() {
                                $('#forLoading').fadeIn(2000);
                                $('#forLoading p.text-primary').fadeIn(2000);
                            }, 7010);
                        });
                    break;

                case "1":
                    fetch('/sc-tdorders')
                        .then(res => res.json())
                        .then(data => {
                            $('#forLoading').fadeOut(3000);
                            setTimeout(function() {
                                thisComponent.pulledOrders = data;
                            }, 3005);
                            setTimeout(function() {
                                $('#pulledData').fadeIn(2000);
                            }, 3010);
                        })
                        .catch(err => {
                            console.log(err.message);
                            $('#forLoading').fadeOut(3000);
                            setTimeout(function() {
                                thisComponent.pulledOrders = 'Unsuccessful';
                            }, 3005);
                            setTimeout(function() {
                                $('#pulledData').fadeIn(2000);
                            }, 3010);
                            setTimeout(function() {
                                $('#pulledData').fadeOut(2000);
                            }, 5000);
                            setTimeout(function() {
                                thisComponent.pulledOrders = '';
                            }, 7000);
                            setTimeout(function() {
                                $('#forLoading').fadeIn(2000);
                                $('#forLoading p.text-primary').fadeIn(2000);
                            }, 7010);
                        });
                    break;

                case "20":
                    fetch('/sc-yesterdaysorders')
                        .then(res => res.json())
                        .then(data => {
                            $('#forLoading').fadeOut(3000);
                            setTimeout(function() {
                                thisComponent.pulledOrders = data;
                            }, 3005);
                            setTimeout(function() {
                                $('#pulledData').fadeIn(2000);
                            }, 3010);
                        })
                        .catch(err => {
                            console.log(err.message);
                            $('#forLoading').fadeOut(3000);
                            setTimeout(function() {
                                thisComponent.pulledOrders = 'Unsuccessful';
                            }, 3005);
                            setTimeout(function() {
                                $('#pulledData').fadeIn(2000);
                            }, 3010);
                            setTimeout(function() {
                                $('#pulledData').fadeOut(2000);
                            }, 5000);
                            setTimeout(function() {
                                thisComponent.pulledOrders = '';
                            }, 7000);
                            setTimeout(function() {
                                $('#forLoading').fadeIn(2000);
                                $('#forLoading p.text-primary').fadeIn(2000);
                            }, 7010);
                        });
                    break;
                case "10":
                    fetch('/sc-last7daysorders')
                        .then(res => res.json())
                        .then(data => {
                            $('#forLoading').fadeOut(3000);
                            setTimeout(function() {
                                thisComponent.pulledOrders = data;
                            }, 3005);
                            setTimeout(function() {
                                $('#pulledData').fadeIn(2000);
                            }, 3010);
                        })
                        .catch(err => {
                            console.log(err.message);
                            $('#forLoading').fadeOut(3000);
                            setTimeout(function() {
                                thisComponent.pulledOrders = 'Unsuccessful';
                            }, 3005);
                            setTimeout(function() {
                                $('#pulledData').fadeIn(2000);
                            }, 3010);
                            setTimeout(function() {
                                $('#pulledData').fadeOut(2000);
                            }, 5000);
                            setTimeout(function() {
                                thisComponent.pulledOrders = '';
                            }, 7000);
                            setTimeout(function() {
                                $('#forLoading').fadeIn(2000);
                                $('#forLoading p.text-primary').fadeIn(2000);
                            }, 7010);
                        });
                    break;

                default:
                    thisComponent.pulledOrders = 'Unsuccessful execution of action';
                    console.log('Unsuccessful Action when selecting: ' + dateSelectorVal);
                    setTimeout(function() {
                        thisComponent.pulledOrders = '';
                    }, 7000);

            }
        });
    }
}

</script>
