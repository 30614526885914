<template>
    <transition name="fade">
        <div class="card-body body-form" v-if="currentOrder">
            <h4 class="card-title mb-3">SPS - Order: {{currentOrder}}</h4>
            <div id="modOrders" class="text-primary line-height-1">{{ modOrder }}</div>
            <div class="row align-items-center justify-content-end">
                <div class="btn btn-outline-white w-10 me-2">Cancel</div>
                <div class="btn btn-warning w-10 me-2">Save & Send</div>
            </div>
        </div>
    </transition>
</template>
<script>
    export default {
        data() {
            return {
                modOrder: '',
                currentOrder: '',
            }
        },
        mounted() {
            console.log('SPS Order Edit Mounted');

            this.init();
        },
        methods: {
            init() {
                $( ".edit-order" ).on( "click", this.get_form );
            },
            get_form( e ) {
                this.currentOrder = $( e.target).data( 'order' );
                $( ".body-table" ).fadeOut( 600 );
                this.modOrder = "Hello World!";

            }
        }
    }
</script>