<template>
    <transition name="fade">
            <div id="fixedOrders" class="text-primary line-height-1" v-if="fixOrders">{{ fixOrders }}</div>
            <div class="text-primary line-height-1" v-else>Checking for completed orders...
                <div class="spinner-border spinner-border-sm" role="status">
                </div>
            </div>
    </transition>
    <transition name="fade">
            <div id="cancelledOrders" class="text-primary line-height-1" v-if="cancelledOrders">{{ cancelledOrders }}</div>
            <div class="text-primary line-height-1" v-else>Checking for cancelled orders...
                <div class="spinner-border spinner-border-sm" role="status">
                </div>
            </div>
    </transition>
</template>
<script>
    export default {
        data() {
            return {
                fixOrders: '',
                cancelledOrders: ''
            }
        },
        mounted() {
            console.log('Engraving Checks Mounted');

            this.perform_checks();
        },
        methods: {
            perform_checks() {
                const token = $('input[name="_token"]').val();
                const url = `/bfs-fixEngravingOrders?_token=${token}`;
                fetch( url, {
                        method: "GET",
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then( res => res.json() )
                    .then( orders => { 
                        if ( orders.length > 0 ) {
                            for (var i = 0; i < orders.length; i++) {
                                if ( orders[i].A_ENGRAVING_NOTEID != undefined ) {
                                    $(`tr[data-note-id="${orders[i].A_ENGRAVING_NOTEID}"]`).remove();
                                }
                                if ( orders[i].B_ENGRAVING_NOTEID != undefined ) {
                                    $(`tr[data-note-id="${orders[i].B_ENGRAVING_NOTEID}"]`).remove();
                                }
                                if ( orders[i].C_ENGRAVING_NOTEID != undefined ) {
                                    $(`tr[data-note-id="${orders[i].C_ENGRAVING_NOTEID}"]`).remove();
                                }
                            }
                            this.fixOrders = `${orders.length} orders have been updated and removed from processing table.`;
                        } else {
                            this.fixOrders = `All completed orders are updated.`;
                        }

                        setTimeout( function() {
                            $( '#fixedOrders' ).fadeOut( '2000' );
                        }, 2000 );
                    })
                    .catch( err => { 
                        this.fixOrders = `Unsuccessful Order Completion Check.`;
                        console.log( err.message );
                    } );

                const url2 = `/sc-cancellationChecks?_token=${token}`;
                fetch( url2, {
                        method: "GET",
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then( res => res.json() )
                    .then( orders => { 
                        if ( orders.length > 0 ) {
                            for (var i = 0; i < orders.length; i++) {
                                if ( orders[i].A_ENGRAVING_NOTEID != undefined ) {
                                    $(`tr[data-note-id="${orders[i].A_ENGRAVING_NOTEID}"]`).remove();
                                }
                                if ( orders[i].B_ENGRAVING_NOTEID != undefined ) {
                                    $(`tr[data-note-id="${orders[i].B_ENGRAVING_NOTEID}"]`).remove();
                                }
                                if ( orders[i].C_ENGRAVING_NOTEID != undefined ) {
                                    $(`tr[data-note-id="${orders[i].C_ENGRAVING_NOTEID}"]`).remove();
                                }
                            }
                            this.cancelledOrders = `${orders.length} cancelled orders have been updated and removed from processing table.`;
                        } else {
                            this.cancelledOrders = `All cancelled orders are updated.`;
                        }

                        setTimeout( function() {
                            $( '#cancelledOrders' ).fadeOut( '2000' );
                        }, 2000 );
                    })
                    .catch( err => { 
                        this.cancelledOrders = `Unsuccessful Order Cancellation Check.`;
                        console.log( err.message );
                    } );
            }
        }
    }
</script>