<template>
    <transition name="fade">
        <div class="card-body body-form" v-if="currentOrder">
            <h4 class="card-title mb-3">SPS - Order: {{ currentOrder }}</h4>
            <div id="modOrders" class="text-primary line-height-1" v-if=formData>
                <!-- <div v-html=modOrder></div> -->
                <div class="container my-5">
                    <h2 class="mb-4">PO Acknowledgement Form</h2>
                    <form>
                      <!-- Order Info -->
                      <div class="d-flex align-items-center mb-3">
                        <label for="orderNumber" class="form-label text-nowrap m-auto me-1">Order Number:</label>
                        <input type="text" class="form-control-plaintext" id="orderNumber" v-model="formData.orderNumber" placeholder="Order # (e.g., RS2816927)">
                      </div>
                      <div class="d-flex align-items-center mb-3">
                        <label for="orderDate" class="form-label text-nowrap m-0 me-1">Order Date:</label>
                        <span id="orderDate" v-text="formData.orderDate"></span>
                      </div>
                      <div class="d-flex align-items-center mb-3">
                        <label for="ackType" class="form-label text-nowrap m-auto me-1">Acknowledgment Type</label>
                        <select class="form-select" id="ackType" v-model="formData.ackType">
                          <option value="AC">AC - Acknowledge With Detail No Change</option>
                          <option value="AD">AD - Acknowledge With Detail Changes</option>
                          <option value="RD">RD - Rejected</option>
                        </select>
                      </div>
                      <div class="d-flex align-items-center mb-3">
                        <label for="ackDate" class="form-label text-nowrap m-auto me-1">Acknowledgment Date:</label>
                        <input type="date" class="form-control" id="ackDate" v-model="formData.ackDate">
                      </div>

                      <!-- Vendor & Shipping Info -->
                      <div class="row mb-3">
                          <div class="col d-flex align-items-center">
                            <label for="vendorNumber" class="form-label text-nowrap m-0 me-1">Vendor Number:</label>
                            <span id="vendorNumber" v-text="formData.vendorNumber"></span>
                          </div>
                      </div>

                      <!-- Line Item Info Table -->
                            <h4 class="mt-4">Line Items Information</h4>
                            <table id="modOrderTable" class="table table-bordered">
                              <thead>
                                <tr>
                                  <th>Line #</th>
                                  <th>SKU</th>
                                  <th>UPC/GTIN</th>
                                  <th>Description</th>
                                  <th>Unit Cost</th>
                                  <th>Original Qty</th>
                                  <th>Item Status</th>
                                  <th>Updated Qty</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(item, index) in formData.lineItems" :key="index">
                                  <!-- Line Number -->
                                  <td>
                                    <div>
                                        <span :id="'LineSequenceNumber' + index" v-text="item.LineSequenceNumber"></span>
                                    </div>
                                  </td>

                                  <!-- SKU -->
                                  <td>
                                    <div>
                                        <span :id="'sku' + index" v-text="item.sku"></span>
                                    </div>
                                  </td>

                                  <!-- UPC/GTIN -->
                                  <td>
                                    <div>
                                        <span :id="'upc' + index" v-html="item.upcGtin"></span>
                                    </div>
                                  </td>

                                  <!-- Description -->
                                  <td>
                                    <div>
                                        <span :id="'description' + index" v-text="item.description"></span>
                                    </div>
                                  </td>

                                  <!-- Unit Cost -->
                                  <td>
                                    <div>
                                        <span :id="'unitCost' + index" v-text="item.unitCost" step="0.01"></span>
                                    </div>
                                  </td>

                                  <!-- Original Quantity -->
                                  <td>
                                    <div>
                                        <span :id="'originalQty' + index" v-text="item.originalqty"></span>
                                    </div>
                                  </td>

                                  <!-- Item Status -->
                                  <td>
                                    <select type="text" class="form-control" :id="'itemStatus' + index" v-model="item.itemStatus">
                                        <option value="IA">Item Accepted</option>
                                        <option value="IQ">Item Quantity Change</option>
                                        <option value="ROS">Rejected Out Of Stock</option>
                                    </select>
                                  </td>

                                  <!-- Updated Quantity -->
                                  <td>
                                    <input type="number" class="form-control" :id="'updatedQty' + index" v-model="item.updatedqty" placeholder="Quantity">
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                      <!-- Notes and Comments -->
                      <div class="mb-3">
                        <label for="notes" class="form-label">Notes/Comments/Special Instructions</label>
                        <textarea class="form-control" id="notes" rows="3" v-model="formData.notes"></textarea>
                      </div>

                      <!-- Buttons -->
                        <div class="btn btn-outline-dark w-10 me-2 cancel-edit" @click="cancelMod">Cancel</div>
                        <div class="btn btn-outline-success w-10 me-2 save-edit" @click="saveEdit">Save & Send</div>
                    </form>
                    <div id="testing"></div>
                  </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    data() {
        return {
            modOrder: '',
            currentOrder: '',
            ack: '',
            formData: {
                orderNumber: '',
                orderDate: '',
                ackType: '',
                ackDate: '',
                vendorNumber: '',
                lineItems: [
                  {
                    LineSequenceNumber: '',
                    sku: '',
                    upcGtin: '',
                    description: '',
                    unitCost: null,
                    originalqty: null,
                    itemStatus: '',
                    updatedqty: null
                  }
                ],
                notes: ''
            }
        }
    },
    mounted() {
        console.log('SPS Order Edit Mounted');
        this.init();
    },
    methods: {
        init() {
            // Instead of using jQuery for event binding, use Vue's own methods.
            document.querySelectorAll(".edit-order").forEach(button => {
                button.addEventListener("click", this.getForm);
            });

        },
        getForm(e) {
            let target = e.target;
            if (target.nodeName === "I") target = target.parentElement;
            this.currentOrder = target.getAttribute('data-order');

            this.buildForm( this.currentOrder, $( 'input[name="_token"]').val() );
        },
        cancelMod() {
            this.currentOrder = '';
            console.log("Edit mode cancelled");
            $( ".body-table" ).fadeIn( 300 );
        },
        saveEdit() {
            if (confirm("Are you sure you want to update this order and resend an acknowledgement?")) {
                
                this.OrderSaveNSend( this.currentOrder, $( 'input[name="_token"]').val(), this.ack );
                this.currentOrder = '';
                this.ack = '';
                console.log("Order saved and sent!");
                $( ".body-table" ).fadeIn( 300 );

            } else {
                console.log("Save and Send was Cancelled!");

            }
            // Add any additional save logic here
        },
        buildForm( orderId, token ) {
            let order = this.orderFetch( orderId, token );
            return order;
        },
        OrderSaveNSend( orderId, token, data ) {
            let url = `/dashboard/admin/sps-save-send-order`;
            var data = {
                'orderId': orderId,
                '_token': token
            };

        },
        orderFetch( orderId, token ) {
            let url = `/dashboard/admin/sps-get-order?orderId=${orderId}&_token=${token}`;
            fetch( url )
                .then(res => res.json())
                .then( data => {
                    $( ".body-table" ).fadeOut( 300 );
                    this.ack = JSON.parse( data.EDI_855 );
                    this.modOrder = "<pre>" + JSON.stringify( JSON.parse( data.EDI_855 ), null, 2 ) + "</pre>";
                    this.formData.orderNumber = this.ack.Header.OrderHeader.PurchaseOrderNumber;
                    this.formData.orderDate = this.formatDate( this.ack.Header.OrderHeader.PurchaseOrderDate );
                    this.formData.ackType = this.ack.Header.OrderHeader.AcknowledgementType;
                    this.formData.ackDate = this.ack.Header.OrderHeader.AcknowledgementDate;
                    this.formData.vendorNumber = this.ack.Header.OrderHeader.Vendor;
                    this.formData.lineItems = [];
                    for (var i = 0; i < this.ack.LineItem.length; i++) {
                        this.addLineItem( this.ack.LineItem[i] );
                    }
                    this.formData.notes = this.ack.Header.Notes[0].Note;

                })
                .catch( err => {
                    console.log( err );
                });
        },
        addLineItem( LineItem ) {
          // Add a new line item object to the lineItems array
          this.formData.lineItems.push({
            LineSequenceNumber: LineItem.OrderLine.LineSequenceNumber,
            sku: LineItem.OrderLine.BuyerPartNumber,
            upcGtin: LineItem.OrderLine.VendorPartNumber + "<br/>" + LineItem.OrderLine.GTIN,
            description: LineItem.ProductOrItemDescription[0].ProductDescription,
            unitCost: LineItem.OrderLine.PurchasePrice,
            originalqty: LineItem.OrderLine.OrderQty,
            itemStatus: LineItem.LineItemAcknowledgement[0].ItemStatusCode,
            updatedqty: LineItem.LineItemAcknowledgement[0].ItemScheduleQty
          });
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const year = date.getFullYear();
            return `${month}/${day}/${year}`;
        }
    }
}
</script>
