/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('./add-user-form');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

import { createApp } from 'vue';
import { select2 } from 'select2';

const Vue = () => createApp({});
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
if ($('#MTDSales').length) {
    Vue().component('month-to-date-sales', require('./components/MonthToDateSales.vue').default).mount('MTDSales');
}

if ($('#MTDOrders').length) {
    Vue().component('month-to-date-orders', require('./components/MonthToDateOrders.vue').default).mount('#MTDOrders');
}

if ($('#sps-order-modify').length) {
    Vue().component('sps-order-modify', require('./components/spsOrderModify.vue').default).mount('#sps-order-modify');
}

if ($('#PendingEngraving').length) {
    Vue().component('pending-engraving', require('./components/pendingEngravingOrders.vue').default).mount('#PendingEngraving');
}

if ($('#OrderPicklists').length) {
    Vue().component('order-picklist-links', require('./components/oldOrderPicklists.vue').default).mount('#OrderPicklists');
}

if ($('#order-checks-container').length) {
    Vue().component('order-checks', require('./components/orderChecks.vue').default).mount('#order-checks-container');
}

if ($('#MTDExpenses').length) {
    Vue().component('month-to-date-expenses', require('./components/MonthToDateExpenses.vue').default).mount('#MTDExpenses');
}

if ($('#LMSales').length) {
    Vue().component('last-month-sales', require('./components/LastMonthSales.vue').default).mount('#LMSales');
}

if ($('#LWSales').length) {
    Vue().component('last-week-sales', require('./components/LastWeekSales.vue').default).mount('#LWSales');
}

if ($('#AOPulling').length) {
    Vue().component('admin-order-pulling', require('./components/AdminOrderPulling.vue').default).mount('#AOPulling');
}

if ($('#member_id').length) {
    $('#member_id').select2({
        placeholder: 'Select Users',
        allowClear: true
    });
}

if ($('#ProductAddBulkModal').length) {
      const preventDefaults = event => {
          event.preventDefault();
          event.stopPropagation();
        };

        const highlight = event =>
          event.target.classList.add('highlight');
        
        const unhighlight = event =>
          event.target.classList.remove('highlight');

        const getInputAndGalleryRefs = element => {
          const zone = element.closest('.upload_dropZone') || false;
          const gallery = zone.querySelector('.upload_gallery') || false;
          const input = zone.querySelector('input[type="file"]') || false;
          return {input: input, gallery: gallery};
        }

        const handleDrop = event => {
          const dataRefs = getInputAndGalleryRefs(event.target);
          dataRefs.files = event.dataTransfer.files;
          $( '#status-area' ).text( dataRefs.files[0].name );
          $( 'label[for="csv_file"]' ).text( 'File Selected');
          handleFiles(dataRefs);
        }


        const eventHandlers = zone => {

          const dataRefs = getInputAndGalleryRefs(zone);
          if (!dataRefs.input) return;

          // Prevent default drag behaviors
          ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(event => {
            zone.addEventListener(event, preventDefaults, false);
            document.body.addEventListener(event, preventDefaults, false);
          });

          // Highlighting drop area when item is dragged over it
          ;['dragenter', 'dragover'].forEach(event => {
            zone.addEventListener(event, highlight, false);
          });
          ;['dragleave', 'drop'].forEach(event => {
            zone.addEventListener(event, unhighlight, false);
          });

          // Handle dropped files
          zone.addEventListener('drop', handleDrop, false);

          // Handle browse selected files
          dataRefs.input.addEventListener('change', event => {
            dataRefs.files = event.target.files;
            $( '#status-area' ).text( dataRefs.files[0].name );
            $( 'label[for="csv_file"]' ).text( 'File Selected');
            handleFiles(dataRefs);
          }, false);

        }


        // Initialise ALL dropzones
        const dropZones = document.querySelectorAll('.upload_dropZone');
        for (const zone of dropZones) {
          eventHandlers(zone);
        }


        // No 'image/gif' or PDF or webp allowed here, but it's up to your use case.
        // Double checks the input "accept" attribute
        const isCSVFile = file => 
          ['text/csv'].includes(file.type);

        // Based on: https://flaviocopes.com/how-to-upload-files-fetch/
        const csvUpload = dataRefs => {            

          // Multiple source routes, so double check validity
          if (!dataRefs.files || !dataRefs.input) return;

          const url = dataRefs.input.getAttribute('data-post-url');
          if (!url) return;

          const name = dataRefs.input.getAttribute('data-post-name');
          if (!name) return;

          const formData = new FormData();
          formData.append( name, dataRefs.files[0] );
          formData.append( '_token', $('.product-add-bulk input[name="_token"]').val() );

          fetch( url, {
            method: 'POST',
            body: formData
          })
          .then( response => response.json() )
          .then( data => {
            if (data.success === true) {
                $( '#results-container' ).html("");
                $( '#results-container' ).fadeIn().html( '<label id="bar-label" for="pregress">' + dataRefs.files[0].name + '</label><div class="progress"><div class="progress-bar" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">0%</div></div><span id="product-count">Processed 0 product names</span>');
                const total = data.processed.length;
                var counter = 0;
                var percentage = 0;
                var timer = 1000;
                data.processed.forEach( function( item, i ) {
                        timer += 25;
                    setTimeout( function() { 
                        counter++;
                        percentage = Math.round( (counter/total)*100 ).toFixed(0);
                        $( 'div.progress-bar' ).attr( 'aria-valuenow', percentage );
                        $( 'div.progress-bar' ).text( percentage + "%" );
                        $( 'div.progress-bar' ).attr( 'style', 'width:' + percentage + '%' );
                        $( '#product-count').text( 'processed ' + counter + ' product names');
                        if ( counter == total ) {
                            setTimeout( function() {
                                resetUpload();
                            }, 800 );
                        }
                    }, timer);
                });
            } else {
              console.log('URL: ', url, '  name: ', name)
            }
          })
          .catch(error => {
            console.error('errored: ', error);
          });
        }


        // Handle both selected and dropped files
        const handleFiles = dataRefs => {

          let files = [...dataRefs.files];

          // Remove unaccepted file types
          files = files.filter(item => {
            if (!isCSVFile(item)) {
              console.log('Not an csv, ', item.type);
            }
            return isCSVFile(item) ? item : null;
          });

          if (!files.length) return;
          dataRefs.files = files;


          csvUpload(dataRefs);
        }

        const resetUpload = () => {
            $( '#results-container' ).fadeOut();
            $( '#results-container' ).html("");
            $( '#csv_file' ).val("");
            $( '#status-area' ).html( "Drag &amp; Drop CSV File<br><i>or</i>" );
            $( 'label[for="csv_file"]' ).text( 'Choose File');
            $( '#ProductAddBulkModal button[data-bs-dismiss="modal"]').click();
        }
}

if (document.getElementById('ProductAddModal')) {
    var productAddModal = document.getElementById('ProductAddModal');
    var productGroupName = document.getElementById('ProductGroupName');
    var productAddModalSave = document.getElementById('ProductAddModalSave');
    var modalTitle = $('.modal-title').text();

    productAddModal.addEventListener('shown.bs.modal', function() {
        productGroupName.focus();
    });

    productAddModalSave.addEventListener('click', function() {
        if (confirm('Are you sure you want to save?') !== true) {
            $('button[data-bs-dismiss="modal"]').click();
            $('.modal-title').text(modalTitle);
            $('.product-add select[name="ProductGroupName"]').prop('disabled', false);
            $('.product-add input[name="ProductID"]').prop('disabled', false);
        } else {

            $('.product-add-results').text('');
            fetch('/product-add', {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },

                    //make sure to serialize your JSON body
                    body: JSON.stringify({
                        _token: $('.product-add input[name="_token"]').val(),
                        product_category: $('.product-add select[name="ProductGroupName"]').val(),
                        product_id: $('.product-add input[name="ProductID"]').val(),
                        record_id: $('.product-add input[name="record_id"]').val(),
                        delete_record: $('.product-add input[name="delete_record"]').val()
                    })
                })
                .then((response) => response.json())
                .then((data) => {
                    if (typeof data === 'object') {
                        console.log(data);
                        $('button[data-bs-dismiss="modal"]').click();
                        $('.product-add-results').text(data.message).delay(5000).fadeOut();
                        setTimeout(function() {
                            $('.product-add-results').text('').fadeIn();
                            $('#record_id').val('');
                            $('#delete_record').val('');
                            window.location.reload();
                        }, 6000);
                    }
                });
        }
    });

    $('.edit-buttons .edit-btn').on('click', function() {
        $('#ProductID').val($(this).closest('tr').children('td:eq(2)').text());
        $('#record_id').val($(this).closest('tr').children('td:eq(0)').text());
        $('.modal-title').text('Product Update For Group');
    });

    $('.edit-buttons .delete-btn').on('click', function() {
        $('#ProductID').val($(this).closest('tr').children('td:eq(2)').text());
        $('#record_id').val($(this).closest('tr').children('td:eq(0)').text());
        $('.product-add select[name="ProductGroupName"]').val($(this).closest('tr').children('td:eq(1)').text());
        $('.product-add select[name="ProductGroupName"]').prop('disabled', true);
        $('.product-add input[name="ProductID"]').prop('disabled', true);
        $('.modal-title').text('Product Remove From Group');
        $('.product-add input[name="delete_record"]').val(true);
    });
}

if ($('#product_id_search').length) {
    $('#product_id_search, #warehouse_id_search').on('keyup change', function(e) {
        var keycode = (e.keyCode ? e.keyCode : e.which);
        if (keycode == '13' || (e.target.id == 'warehouse_id_search' && e.type == 'change')) {
            if ('' != $('#product_id_search').val()) {
                $('.inventory-results').text('');
                fetch('/sc-inventory', {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },

                        //make sure to serialize your JSON body
                        body: JSON.stringify({
                            _token: $('input[name="_token"]').val(),
                            product_id: $('#product_id_search').val().toUpperCase().substring(0, 10),
                            warehouse_id: $('#warehouse_id_search').val().substring(0, 4)
                        })
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        if (typeof data.inventory_warehouses_response === 'object') {
                            console.log(data.inventory_warehouses_response);
                            var inventory_table = '';
                            inventory_table += '<table class="table table-responsive">';
                            inventory_table += '<thead>';
                            inventory_table += '<tr>';
                            Object.keys(data.inventory_warehouses_response[0]).forEach(function(el, i) {
                                inventory_table += '<th scope="col">';
                                inventory_table += el;
                                inventory_table += '</th>';
                                if (Object.keys(data.inventory_warehouses_response[0]).length - 1 == i) {
                                    inventory_table += '<th scope="col">';
                                    inventory_table += 'Warehouse Name';
                                    inventory_table += '</th>';
                                }
                            });
                            inventory_table += '</tr>';
                            inventory_table += '</thead>';
                            inventory_table += '<tbody>';
                            Object.keys(data.inventory_warehouses_response).forEach(function(el, i) {
                                inventory_table += '<tr>';
                                inventory_table += '<td>';
                                inventory_table += data.inventory_warehouses_response[el].ProductID;
                                inventory_table += '</td>';
                                inventory_table += '<td>';
                                inventory_table += data.inventory_warehouses_response[el].AvailableQty;
                                inventory_table += '</td>';
                                inventory_table += '<td>';
                                inventory_table += data.inventory_warehouses_response[el].PhysicalQty;
                                inventory_table += '</td>';
                                inventory_table += '<td>';
                                inventory_table += data.inventory_warehouses_response[el].WarehouseID;
                                inventory_table += '</td>';
                                inventory_table += '<td>';
                                switch (data.inventory_warehouses_response[el].WarehouseID) {
                                    case 205:
                                        inventory_table += 'Amazon 1';
                                        break;
                                    case 186:
                                        inventory_table += 'Amazon 2';
                                        break;
                                    case 185:
                                        inventory_table += 'Amazon 3';
                                        break;
                                    case 183:
                                        inventory_table += 'Amazon 4';
                                        break;
                                    case 201:
                                        inventory_table += 'AMZ to BFS';
                                        break;
                                    case 187:
                                        inventory_table += 'Drop Ship';
                                        break;
                                    case 184:
                                        inventory_table += 'FBA Warehouse';
                                        break;
                                    case 204:
                                        inventory_table += 'Gorst - Main';
                                        break;
                                    case 191:
                                        inventory_table += 'Megan West';
                                        break;
                                    case 208:
                                        inventory_table += 'Skustack';
                                        break;
                                    case 182:
                                        inventory_table += 'Smart Warehousing';
                                        break;
                                    case 192:
                                        inventory_table += 'Snap360';
                                        break;
                                    case 198:
                                        inventory_table += 'Trailer';
                                        break;
                                }
                                inventory_table += '</td>';
                                inventory_table += '</tr>';
                            });
                            inventory_table += '</tbody>';
                            inventory_table += '</table>';
                        }
                        $('.inventory-results').html(((typeof data.inventory_warehouses_response === 'object') ? inventory_table : data.inventory_warehouses_response));
                    });
            }

        }
    });
}

if ($('#order_notes').length) {
    $('#order_notes').on('change', function(e) {
        if ('null' != e.target.value) {
            $('.notes-results').text('');
            fetch('/sc-notes', {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },

                    //make sure to serialize your JSON body
                    body: JSON.stringify({
                        _token: $('input[name="_token"]').val(),
                        order_id: $('#order_notes').val(),
                    })
                })
                .then((response) => response.json())
                .then((data) => {
                    data.Order_Notes_response.forEach(function(note, i) {
                        if ('null' != note.Note) {
                            var matches = note.Note.match(/(?=(?:^Product|(?<=\()ID|Line [1-5]:) )[^\(\),]+|(?=(?:Text Customization --|Engraving Text|Line [1-5] --) )[^\)]+\)/);
                            if (null != matches) {
                                $('.notes-results').text(matches.input);
                            }
                        }
                    });
                });
        }
    });
}

if ($('#order_custom_columns').length) {
    $('#order_custom_columns').on('change', function(e) {
        if ('null' != e.target.value) {
            $('.custom-columns-results').text('');
            fetch('/sc-custom-columns', {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },

                    //make sure to serialize your JSON body
                    body: JSON.stringify({
                        _token: $('input[name="_token"]').val(),
                        order_id: $('#order_custom_columns').val(),
                    })
                })
                .then((response) => response.json())
                .then((data) => {
                    $('.custom-columns-results').text('');
                    var text = '';
                    data.Custom_Columns_response.forEach(function(column, i) {
                        text += JSON.stringify(column);
                    });
                    console.log(text);
                    $('.custom-columns-results').text(text);
                });
        }
    });
}

if ($('#order_notes').length) {
    $('#order_notes').select2({
        placeholder: 'Select an Order Id.',
        allowClear: true,
        dropdownAutoWidth: true,
        width: 'auto'
    });
    $('.select2-container').addClass('form-select');
}

if ($('#order_custom_columns').length) {
    $('#order_custom_columns').select2({
        placeholder: 'Select an Order Id.',
        allowClear: true,
        dropdownAutoWidth: true,
        width: 'auto'
    });
    $('.select2-container').addClass('form-select');
}

if ($('#info-edit-btn').length) {
    const note_ids = $('table>tbody>tr').prop('id');
    $('#info-edit-btn').on('click', (e) => {
        if ($('.info-save-btn').width() == 0) {
            if ($('.info-save-btn>button').is(':disabled')) {
                $('.info-save-btn>button').prop('disabled', false);
            }
            $(e.target).text('Cancel');
            $('.info-save-btn').addClass('active-edit');
            if ($.type(note_ids) != 'string' && note_ids.length > 1) {
                note_ids.forEach((id, i) => {
                    $('#' + 'engraving_status_' + id).prop('disabled', false);
                });
            } else {
                $('#' + 'engraving_status_' + note_ids).prop('disabled', false);
            }
        } else {
            $(e.target).text('Edit');
            $('.info-save-btn').removeClass('active-edit');
            if ($.type(note_ids) != 'string' && note_ids.length > 1) {
                note_ids.forEach((id, i) => {
                    $('#' + 'engraving_status_' + id).prop('disabled', true);
                });
            } else {
                $('#' + 'engraving_status_' + note_ids).prop('disabled', true);
            }
        }
    });
    $('.info-save-btn>button').on('click', (e) => {
        var order_ids = [];
        var inputNote_ids = [];
        var engraving_statuses = {};
        $(e.target).prop('disabled', true);
        if ($.type(note_ids) != 'string' && note_ids.length > 1) {
            note_ids.forEach((i, id) => {
                $('#' + 'engraving_status_' + id).prop('disabled', false);
                order_ids.push($('#order_id_' + id).val());
                inputNote_ids.push($('#note_id_' + id).val());
                engraving_statuses[id] = $('#engraving_status_' + id).val();
                $('#engraving_status_' + id).prop('disabled', true);
            });
        } else {
            $('#' + 'engraving_status_' + note_ids).prop('disabled', false);
            order_ids.push($('#order_id_' + note_ids).val());
            inputNote_ids.push($('#note_id_' + note_ids).val());
            engraving_statuses[note_ids] = $('#engraving_status_' + note_ids).val();
            $('#engraving_status_' + note_ids).prop('disabled', true);
        }
        fetch('/dashboard/engraving-info-update-status', {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                //make sure to serialize your JSON body
                body: JSON.stringify({
                    _token: $('input[name="_token"]').val(),
                    order_ids: order_ids,
                    note_ids: inputNote_ids,
                    engraving_statuses: engraving_statuses
                })
            })
            .then((response) => response.json())
            .then((data) => {
                $('.info-save-btn>button').html("Saved <i class='bi bi-check2-circle'></i>");
                console.log(data);
                const response_obj = data.fragments.status_update;
                for (const key in response_obj) {
                    if ( response_obj[key] == 1 ) {
                        $('#processing_upc').prop('disabled', false ).val('');
                    }
                    if ($('#engraving_status_' + key).is(':disabled')) {
                        $('#engraving_status_' + key).prop('disabled', false);
                        $('#engraving_status_' + key + '>option[value="' + response_obj[key] + '"]').prop('selected', true);
                        $('#engraving_status_' + key).prop('disabled', true);
                    } else {
                        $('#engraving_status_' + key + '>option[value="' + response_obj[key] + '"]').prop('selected', true);
                        $('#engraving_status_' + key).prop('disabled', true);
                    }
                };
                setTimeout(function() {
                    $('.info-save-btn').removeClass('active-edit');
                    $('#info-edit-btn').text('Edit');
                    $('.info-save-btn>button').text('Save');
                }, 2000);
            });
    });
}

if ($('#processing_upc').length) {
    $('#processing_upc').focus();
    var init_completed = true;
    $('tbody tr').each(function(i, el) {
        if ($('#end_time_' + el.id).text() == '') {
            init_completed = false;
        }
        if ($('#start_time_' + el.id).text() != '' && $('#end_time_' + el.id).text() == '') {
            $('#processing_upc').val($('#product_upc_' + el.id).val());
            $('#processing_upc').prop('disabled', true);
            var minutesLabel = document.getElementById("minutes");
            var secondsLabel = document.getElementById("seconds");
            var totalSeconds = 0;
            setInterval(setTime, 1000);

            function setTime() {
                ++totalSeconds;
                secondsLabel.innerHTML = pad(totalSeconds % 60);
                minutesLabel.innerHTML = pad(parseInt(totalSeconds / 60));
            }

            function pad(val) {
                var valString = val + "";
                if (valString.length < 2) {
                    return "0" + valString;
                } else {
                    return valString;
                }
            }
            $('.processing-counter').fadeIn(2000);
            $('#step_6_flow').fadeIn(2000);
        }
        if ($('#end_time_' + el.id).text() != '' && init_completed) {
            if ($('tbody tr').length == i + 1) {
                $('#processing_upc').prop('disabled', true);
                $('#completed-order').fadeIn(2000);
            }
        }
        // finished page load checks.

        $(document).on('select2:open', () => {
            document.querySelector('.select2-search__field').focus();
        });

        $('#processing_upc').on('keyup', function(e) {
            if (e.key === 'Enter' && $('#product_upc_' + el.id).val() == $('#processing_upc').val() && $('#start_time_' + el.id).text() == '' || $('#product_upc_' + el.id).val() == $('#processing_upc').val() && $('#start_time_' + el.id).text() == '') {
                // $('#processing_upc').prop('disabled', true);

                $('#processing_upc').on('change', function() {
                    fetch('/dashboard/engraving-info-update', {
                            method: "POST",
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            //make sure to serialize your JSON body
                            body: JSON.stringify({
                                _token: $('input[name="_token"]').val(),
                                order_id: $('#order_id_' + el.id).val(),
                                note_id: $('#note_id_' + el.id).val(),
                                product_id: $('#product_id_' + el.id).val(),
                                product_upc: $('#product_upc_' + el.id).val(),
                                user_id: $('#user_id_' + el.id).val(),
                                engraving_status: $('#engraving_status_' + el.id).val(),
                                engraving_category: $('#engraving_category_' + el.id).val()
                            })
                        })
                        .then((response) => response.json())
                        .then((data) => {
                            $('#start_time_' + el.id).text(data.fragments.start_time);
                            if ($('#engraving_status_' + el.id).is(':disabled')) {
                                $('#engraving_status_' + el.id).prop('disabled', false);
                                $('#engraving_status_' + el.id + '>option[value="' + data.fragments.status_update + '"]').prop('selected', true);
                                $('#engraving_status_' + el.id).prop('disabled', true);
                            }
                            var minutesLabel = document.getElementById("minutes");
                            var secondsLabel = document.getElementById("seconds");
                            var totalSeconds = 0;
                            setInterval(setTime, 1000);

                            function setTime() {
                                ++totalSeconds;
                                secondsLabel.innerHTML = pad(totalSeconds % 60);
                                minutesLabel.innerHTML = pad(parseInt(totalSeconds / 60));
                            }

                            function pad(val) {
                                var valString = val + "";
                                if (valString.length < 2) {
                                    return "0" + valString;
                                } else {
                                    return valString;
                                }
                            }
                            $('.processing-counter').fadeIn(2000);
                            $('#step_6_flow').fadeIn(2000);
                        });
                });
            }
            if (e.key === 'Enter' && $('#product_upc_' + el.id).val() != $('#processing_upc').val()) {
                $('#item_verification').modal('show');
                $('.modal-footer button').focus();
                $('#item_verification').on('hidden.bs.modal', function() {
                    $('#processing_upc').val('');
                    $('#processing_upc').focus();
                });
            }
        });

        $('#save-close,#save-next').on('click', function(e) {
            console.log(e);
            if ($('#start_time_' + el.id).text() != '' && $('#end_time_' + el.id).text() == '') {
                fetch('/dashboard/engraving-info-complete', {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        //make sure to serialize your JSON body
                        body: JSON.stringify({
                            _token: $('input[name="_token"]').val(),
                            order_id: $('#order_id_' + el.id).val(),
                            note_id: $('#note_id_' + el.id).val(),
                            product_id: $('#product_id_' + el.id).val(),
                            product_upc: $('#product_upc_' + el.id).val(),
                            user_id: $('#user_id_' + el.id).val(),
                            engraving_status: $('#engraving_status_' + el.id).val(),
                            engraving_category: $('#engraving_category_' + el.id).val()
                        })
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        $('#end_time_' + el.id).text(data.fragments.end_time);
                        console.log( data );
                        if ($('#engraving_status_' + el.id).is(':disabled')) {
                            $('#engraving_status_' + el.id).prop('disabled', false);
                            $('#engraving_status_' + el.id + '>option[value="' + data.fragments.status_update + '"]').prop('selected', true);
                            $('#engraving_status_' + el.id).prop('disabled', true);
                        }
                        $('.processing-counter').fadeOut(1500);
                        var completed = true;
                        $('tbody tr').each(function(i, el) {
                            if ($('#end_time_' + el.id).text() == '') {
                                completed = false;
                                $('#step_6_flow').fadeOut(2000);
                                $('#processing_upc').prop('disabled', false);
                                $('#processing_upc').val('');
                            }
                        });
                        if (completed) {
                            $('#completed-order').fadeIn(1500);
                            if (e.target.id == 'save-next') {
                                setTimeout(function() {
                                    window.location = "/dashboard/engraving-info?sc_id=" + data.next_engraving_order
.SC_ID + "&note_id=" + data.next_engraving_order
.NoteID;
                                }, 2000);
                            } else {

                                setTimeout(function() {
                                    window.location = "/dashboard/engraving";
                                }, 2000);
                            }
                        }
                        if ($('tbody tr').length != i + 1) {
                            $('#step_6_flow').fadeOut(2000);
                            $('#processing_upc').prop('disabled', false);
                            $('#processing_upc').val('');
                        }
                    });
            }
        });

        // endif    
    });

}

if ($('#bulk_print').length) {
    $('#select_all').on('change', function(e) {
        if ($(e.target).is(':checked')) {
            $('tbody input[type="checkbox"]').prop('checked', true);
        } else {
            $('tbody input[type="checkbox"]').prop('checked', false);
        }
    });
    $('#bulk_print').on('click', function() {
        var order_ids = [];
        var note_ids = [];
        $('tbody input[type="checkbox"]').each(function(i, checkbox) {
            if ($(checkbox).is(':checked')) {
                order_ids.push($(checkbox).val());
                note_ids.push($(checkbox).attr('noteid'));
            }
        });

        if (order_ids != '') {
            fetch('/engraving-bulk-print', {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    //make sure to serialize your JSON body
                    body: JSON.stringify({
                        _token: $('input[name="_token"]').val(),
                        order_ids: order_ids,
                        note_ids: note_ids,
                    })
                })
                .then(response => {
                    // Check if the response is successful
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }

                    // Get content type from response headers
                    const contentType = response.headers.get('content-type');

                    // Check if the response is a PDF
                    if (contentType && contentType.includes('application/pdf')) {
                        // Return the response blob
                        return response.blob();
                    } else {
                        throw new Error('Response is not a PDF');
                    }
                })
                .then(pdfBlob => {
                    // Create a URL for the PDF blob
                    const pdfUrl = URL.createObjectURL(pdfBlob);

                    // Open the PDF in a new tab or window
                    window.open(pdfUrl);
                })
                .catch(error => {
                    console.error('There was a problem with the fetch operation:', error);
                });
        }
    });
}
$('input[type="checkbox"]').prop('checked', false);

if ($('#save-settings').length) {
    $('#save-settings').on('click', function(e) {
        e.preventDefault();
        var button_text_og = $('#save-settings').text();
        var json_settings = $('.app-settings-wrap form').serializeArray().filter(function(item) { return item.name != '_token'; });
        console.log(json_settings);
        fetch('/save-app-settings', {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                //make sure to serialize your JSON body
                body: JSON.stringify({
                    _token: $('input[name="_token"]').val(),
                    app_settings: json_settings,
                })
            })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                $('#save-settings').text(data.response_text);
                setTimeout(function() {
                    $('#save-settings').text(button_text_og);
                }, 3000);
            });
    });
}
