<template>
    <p class="text-primary text-14 line-height-1 mb-2" v-if="weekworkhours">{{weekworkhours}}</p>
    <p class="text-primary text-14 line-height-1 mb-2" v-else>0</p>
</template>

<script>
    export default {
        data() {
            return {
                weekworkhours: ''
            }
        },
        mounted() {
            console.log('Week Work Hours Mounted');
            fetch( '/bfs-weekworkhours' )
                .then( res => res.json() )
                .then( data => this.weekworkhours = data )
                .catch( err => { 
                    this.weekworkhours = 'Unsuccessful';
                    console.log( err.message );
                } );

        }
    }
</script>
